import './App.css';
import React, { useState } from 'react';
import Scan from './containers/Scan';
import Write from './containers/Write';
import { ActionsContext } from './contexts/context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap 5 CSS

// Import SVG files
import illustrations from './icons/illustrations.png';
import start_shift from './icons/start_shift.png';
import end_shift from './icons/end_shift.png';
import start_break from './icons/start_break.png';
import end_break from './icons/end_break.png';
import write_card from './icons/write_card.png';
import logo from './icons/logo.png';
import bg from './icons/bg.png';

function App() {
  const [actions, setActions] = useState(null);
  const { scan, write, shift } = actions || {};

  const actionsValue = { actions, setActions };

  const onHandleAction1 = (actions) => {
    setActions({ ...actions });
  };

  const onHandleAction = (shiftValue) => {
    setActions({ scan: 'scanning', write: null, shift: shiftValue });
  };

  return (
    <div className='container bg-pic'>
      <div className='row'>
        <div className='col-6'>
        <img src={logo} alt='icon' className='' style={{ width:'60px',marginLeft:'10px',marginTop:'10px'}} />
        </div>
        <div className='col-6'>
        
        </div>
      </div>
      <div style={{width:'70%', margin:'auto', marginTop:'50px'}}>
        <img src={illustrations} alt='icon' className='' style={{ margin:'auto', width:'100%'}} />
      </div>
      
      {/* Illustrations at the top */}

      <h1 className='text-center mt-5 mb-4'>NFC Attendance System</h1>

      {/* Button icons and buttons */}
      <div className='row'>
        <div className='col-2 offset-1'>
          <img src={start_shift} alt='icon' className='img-fluid img-responsive img-rounded' style={{ width:'70px', height:'40px'}} />
        </div>
        <div className='col-8'>
          <button
            onClick={() => onHandleAction('start_shift')}
            className='btn btn-block border-info mb-3 py-2' style={{width:'100%' }}
          >
            Start Shift
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-2 offset-1'>
          <img src={end_shift} alt='icon' className='img-fluid img-responsive img-rounded' style={{ width:'70px', height:'40px'}} />
        </div>
        <div className='col-8'>
          <button
            onClick={() => onHandleAction('end_shift')}
            className='btn btn-block border-info mb-3 py-2' style={{width:'100%' }}
          >
            End Shift
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-2 offset-1'>
          <img src={start_break} alt='icon' className='img-fluid img-responsive img-rounded' style={{ width:'70px', height:'40px'}} />
        </div>
        <div className='col-8'>
          <button
            onClick={() => onHandleAction('start_break')}
            className='btn btn-block border-info mb-3 py-2' style={{width:'100%' }}
          >
            Start Break
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-2 offset-1'>
          <img src={end_break} alt='icon' className='img-fluid img-responsive img-rounded' style={{ width:'70px', height:'40px'}} />
        </div>
        <div className='col-8'>
          <button
            onClick={() => onHandleAction('end_break')}
            className='btn btn-block border-info mb-3 py-2' style={{width:'100%' }}
          >
            End Break
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-2 offset-1'>
          <img src={write_card} alt='icon' className='img-fluid img-responsive img-rounded' style={{ width:'70px', height:'40px'}} />
        </div>
        <div className='col-8'>
          <button
            onClick={() => onHandleAction1({ scan: null, write: 'writing' })}
            className='btn btn-block border-info py-2' style={{width:'100%' }}
          >
            Write
          </button>
        </div>
      </div>

      {/* Render Scan or Write components based on state */}
      <ActionsContext.Provider value={actionsValue}>
        {write && <Write />}
        {scan && <Scan />}
      </ActionsContext.Provider>

      <ToastContainer position='top-right' />
    </div>
  );
}

export default App;
