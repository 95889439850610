import React, { useCallback, useContext, useEffect, useState } from 'react';
import Scanner from '../components/Scanner/Scanner';
import { ActionsContext } from '../contexts/context';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Scan = () => {
  const [message, setMessage] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const { actions, setActions } = useContext(ActionsContext);

  const showToast = (message) => {
    toast.info(`Message: ${message}`);
  };

  const scan = useCallback(async () => {
    if ('NDEFReader' in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan();

        console.log("Scan started successfully.");

        ndef.onreadingerror = () => {
          console.log("Cannot read data from the NFC tag. Try another one?");
        };

        ndef.onreading = event => {
          console.log("NDEF message read.");
          onReading(event);
          setActions({
            scan: 'scanned',
            write: null
          });
        };
      } catch (error) {
        console.error(`Error! Scan failed to start: ${error}`);
      }
    } else {
        console.error("NDEFReader not available in this browser.");
    }
  }, [setActions]); // Include onReading in the dependency array

  const onReading = async ({ message, serialNumber }) => {
    setSerialNumber(serialNumber);

    // Initialize an empty array to store message parts
    const messageParts = [];

    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          const textDecoder = new TextDecoder(record.encoding);
          setMessage(textDecoder.decode(record.data));
          const text = textDecoder.decode(record.data);
          messageParts.push(`Text: ${text}`);
          const emp_id = textDecoder.decode(record.data);

          // Use Geolocation API to get current latitude and longitude
          navigator.geolocation.getCurrentPosition(async (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;
            const dataToSend = {
              action: 'mark_attendance_geolocation',
              business_id: '41',
              emp_id: emp_id,
              shift: actions.shift, // Use the shift value passed from App.js
              lat: lat,
              lon: lon
            };

            // Send the data to your API
            try {
              const response = await axios.post('https://attendezz.com/dashboard/api/index.php', null, {
                params: dataToSend,
              });
              console.log('Data sent successfully:', response.data);
              var responseData = response.data;
              setMessage(responseData.msg);
              
              showToast(responseData.msg); // Show toast here

              setInterval(function(){ window.location.reload();}, 5000);

            } catch (error) {
              console.error('Error sending data:', error);
              setMessage('Error sending data');
            }
          }, (error) => {
            console.error('Error getting location:', error);
          });
          break;
        case "url":
            // TODO: Read URL record with record data.
            break;
        default:
            // TODO: Handle other records with record data.
      }
    }

    // const formattedMessage = messageParts.join("\n");

    // setMessage(formattedMessage);

    // showToast(formattedMessage);
  };

  useEffect(() => {
    scan();
  }, [scan]);

  return (
  <>
    {actions.scan === 'scanned' ?
      <div>
        {/* <p>Serial Number: {serialNumber}</p>
        <p>Message: {message}</p> */}
      </div>
      : <Scanner status={actions.scan}></Scanner>}
  </>
  );
};

export default Scan;
