import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Write = () => {
  const [parameterName, setParameterName] = useState('');
  const [parameterValue, setParameterValue] = useState('');
  const [nfcMessage, setNfcMessage] = useState('');
  var urlParams = new URLSearchParams(window.location.search);
  var paramName = 'emp_id'; // Replace 'name' with the parameter name you are looking for
  var paramValue = urlParams.get(paramName);
  const fetchParameterValue = () => {
    if (paramValue) {
      setParameterName(paramName);
      setParameterValue(paramValue);
    } else {
      setParameterName('');
      setParameterValue('Parameter not found');
    }
    
  };

  useEffect(() => {
      // Define the data object to send in the POST request

      const dataToSend = {
        action: 'post_serial_number',
        emp_id: paramValue,
        // Add more key-value pairs as needed
      };

    // Fetch parameters from an API endpoint (replace with your API URL)
    axios.post('https://attendezz.com/dashboard/api/index.php', null, {
      params: dataToSend,
    })
      .then((response) => {
        const data = response.data;
        setNfcMessage(data.msg);

      console.log(data)
      })
      .catch((error) => {
        console.error(error);
        
      });
    fetchParameterValue();
  }, [])

  const onWrite = async () => {
    try {
      const ndef = new window.NDEFReader();
      // This line will avoid showing the native NFC UI reader
      await ndef.scan();
      await ndef.write({ records: [{ recordType: 'text', data: parameterValue }] });
      
      setNfcMessage('Value Saved!');
    } catch (error) {
      console.error(error);
    }
  };
  onWrite();
  return (
    <div className='container mt-2 text-center'>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-8'>
          <input type='text' value={parameterValue} className='form-control'/>
        </div>
        {/* <div className='col-3'>
          <button onClick={onWrite} className='btn btn-success btn-sm'>Write to NFC</button>
        </div> */}
      </div>
      {nfcMessage && <p>{nfcMessage}</p>}
    </div>
  );
};

export default Write;
  